<template>
  <main>
    <div v-if="offerings" class="container page-content">
      <div class="row pb-3">
        <div class="col">
          <h3>#{{ tag }} Offerings</h3>
        </div>
        <div class="col">
          <router-link class="btn float-end" :to="{ name: 'OfferingsWatch' }">
            Show Watch List <i class="bi bi-bookmark-heart-fill btn-link"></i>
          </router-link>
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div
              v-for="offering in offeringsWithTag"
              :key="offering.id"
              class="col"
            >
              <OfferingSingle :offering="offering" />
            </div>
            <div class="text-center" v-if="offeringsWithTag.length == 0">
              <p>No offerings exist with this tag.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import TagCloud from "@/components/tags/TagCloud.vue";
import getCollection from "@/composables/getCollection";
import { computed } from "@vue/runtime-core";

export default {
  props: ["tag"],
  components: { OfferingSingle, Footer, TagCloud },
  setup(props) {
    const { error, documents: offerings } = getCollection(
      "offerings",
      [
        ["featured", ">=", 0],
        ["published", "==", true]
      ],
      ["featured", "desc"]
    );

    const offeringsWithTag = computed(() => {
      return offerings.value.filter(offering =>
        offering.tags.includes(props.tag)
      );
    });

    return { error, offerings, offeringsWithTag };
  }
};
</script>

<style scoped>
</style>